import { CloseOutlined, CustomerServiceOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Layout, Row, Menu, Avatar, Space, Button, Dropdown } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import logolight from "../assets/images/logo.png";
import { useProfile } from "../components/hooks/UserHooks";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const items = [
    {
        label: 'Sản xuất',
        key:'manufacture',
        children: [
            {
                label: 'Quản lý giấy và bảo ôn',
                key:'manufacture/giay-bao-on',
            },
            {
                label: 'Kế hoạch sản xuất',
                key:'manufacture/ke-hoach-san-xuat',
            },
            {
                label: 'Lịch sử sản xuất',
                key:'manufacture/lich-su-san-xuat',
            },
            {
                label: 'Quản lý sản lượng',
                key:'manufacture/quan-ly-san-luong',
            },
            {
                label: 'Đơn hàng',
                key:'manufacture/order',
            },
            {
                label: 'Tạo kế hoạch',
                key:'manufacture/tao-ke-hoach',
            },
            {
                label: 'Kế hoạch tự động',
                key:'manufacture/khsx-auto',
            },
            {
                label: 'FC',
                key:'manufacture/fc',
            },
            {
                label: 'Lệnh giao hàng',
                key:'manufacture/lenh-giao-hang',
            },
            
        ],
        
        permission:'ui-sx'
    },
    {
        label: 'Chất lượng',
        key:'quality',
        children:[
            {
                label: 'IQC',
                key:'quality/IQC',
            },
            {
                label: 'PQC',
                key:'quality/PQC',
            },
            {
                label: 'OQC',
                key:'quality/OQC',
            },
            {
                label: 'Lỗi',
                key:'quality/errors',
            }
        ],
        permission:'ui-cl'
    },
    {
        label: 'Thiết bị',
        key:'equipment',
        children:[
            {
                label: 'Thống kê lỗi',
                key:'equipment/thong-ke-loi',
            },
            {
                label: 'Thông số máy',
                key:'equipment/thong-so-may',
            },
            {
                label: 'Điện năng tiêu thụ',
                children: [
                    {
                        label: 'Theo tháng',
                        key: 'equipment/dien-nang-tieu-thu-theo-thang',
                    },
                    {
                        label: 'Theo sản phẩm',
                        key: 'equipment/dien-nang-tieu-thu-theo-sp',
                    }
                ],
                key: 'equipment/dien-nang-tieu-thu'
            },
            {
                label: 'Bảo trì, bảo dưỡng',
                children:[
                    {
                        label: 'Kế hoạch thực hiện BTBD',
                        key:'equipment/maintenance',
                    },
                    {
                        label: 'Chi tiết thực hiện kiểm tra',
                        key:'equipment/detail-maintenance',
                    }
                ],
            },
        ],
        permission:'ui-tb'
    }, 
    {
        label: 'Kho',
        key:'warehouse',
        children:[
            {
                label: 'Quản lý kho thành phẩm giấy',
                key:'warehouse/thanh-pham-giay',
            },
            {
                label: 'Kế hoạch xuất kho',
                key:'warehouse/ke-hoach-xuat-kho',
            }
        ],
        permission:'ui-kho'
    },
    {
        label: 'KPI',
        key:'kpi',
        permission:'ui-kpi'
    },
    // {
    //     label: 'Giám sát bất thường',
    //     key:'abnormal',
    //     children:[
    //         {
    //             label: 'Kịch bản bất thường',
    //             key:'abnormal/kich-ban-bat-thuong',
    //         },
    //         {
    //             label: 'Lịch sử bất thường',
    //             key:'abnormal/lich-su-bat-thuong',
    //         }
    //     ],
    //     permission:'ui-abnormal'
    // },
    {
        label: 'Master Data',
        key:'master-data',
        children: [
            {
                label: 'Sản phẩm',
                key:'master-data/product',
            },
            {
                label: 'Nguyên vật liệu',
                key:'master-data/material',
            },
            {
                label: 'BOM',
                key:'master-data/bom',
            },
            {
                label: 'Hành trình sản xuất',
                key:'master-data/production-journey',
            },
            {
                label: 'Nhân sự',
                key:'master-data/user',
            },
            {
                label: 'Khách hàng',
                key:'master-data/customer',
            },
            // {
            //     label: 'Đơn hàng',
            //     key:'master-data/product-order',
            // },
            {
                label: 'Template',
                key:'master-data/template',
            },
            // {
            //     label: 'Tem',
            //     key:'master-data/tem',
            // },
            {
                label: 'Máy',
                key:'master-data/machine',
            },
            {
                label: 'Thứ tự ưu tiên máy',
                key:'master-data/machine-priority-order',
            },
            {
                label: 'Ca làm việc',
                key:'master-data/shift',
            },
            {
                label: 'Phân ca máy',
                key:'master-data/machine-shift',
            },
        ], 
        permission:'master'
    },
]
const HeaderUI = () => {
    const { userProfile } = useProfile();
    const [clock, setClock] = useState(new Date())
    useEffect(()=>{
        setInterval(() => tick(), 1000);
    }, [])
    const tick = () => {
        setClock(new Date());
    }

    const [ui, setUI] = useState(window.location.pathname.split('/ui/')[1]);
    const [userInfo, setUserInfo] = useState()
    const history = useHistory();
    const selectMenu = (key) => {
        let r = '/ui/' + key.key
        history.push(r);
        setUI(key.key)
    }
    useEffect(()=>{
        console.log(ui);
    }, [ui])
    const logout = () =>{
        window.location.href = '/logout';
    }
    const itemsDropdown = [
        {
            key: '1',
            label: 'Đăng xuất',
            icon: <LogoutOutlined />,
            onClick: ()=>logout(),
        },
    ]
    return (
        <React.Fragment>
            <Layout style={{top: 0, left: 0, width:'100%', height: 60, backgroundColor:'#2462A3', paddingLeft:'10px', paddingRight:'10px', paddingTop:'2px', paddingBottom:'2px'}}>
                  <Row style={{alignItems:'center'}}>
                        <Col span = {4}>
                              <div className="demo-logo" style={{ display: 'flex'}} >
                              <img style={{ height: '80%'}} src={logolight} />
                              </div>
                        </Col>
                        <Col span = {14}>
                            <Menu
                                mode="horizontal"
                                defaultSelectedKeys={[ui]}
                                selectedKeys={[ui]}
                                style={{
                                    // width: '100%',
                                    // height: '100%',
                                    alignItems: 'center',
                                    background: '#2462a3',
                                    color: '#fff',
                                    fontWeight: '600',
                                    // maxWidth:'max-content'
                                }}
                                items={items.filter(e=>(userProfile?.permission??[]).includes('*') || (userProfile?.permission??[]).includes(e.permission))}
                                onSelect={selectMenu}
                            />
                        </Col>
                        <Col span={6} className='text-end align-items-center d-flex justify-content-end'>
                            <Link to={'/ui/voc'} style={{float:'right',color:'white', paddingRight:'10px', alignContent:'center', display:'flex'}} className='h-100'>
                                <Avatar size='middle' style={{backgroundColor:'white', marginLeft:'10px'}}><CustomerServiceOutlined style={{color:'black', fontSize: '18px'}} /></Avatar>
                            </Link>
                            <Dropdown menu={{items:itemsDropdown}} placement="bottomRight" arrow trigger={'click'}>
                                <Button type='text' style={{float:'right',color:'white', paddingRight:'10px', alignContent:'center', display:'flex'}} className='h-100'>
                                    <div style={{textAlign:'center'}}>
                                        {userProfile?.name ?? 'User name'}
                                        <div>{clock.toLocaleString(['en-GB'], { hour12: false })}</div>
                                    </div>
                                    <Avatar size='large' style={{backgroundColor:'white', marginLeft:'10px'}} src={userProfile?.avatar} icon={<UserOutlined style={{color:'black'}}/>}></Avatar>
                                </Button>
                            </Dropdown>
                            <Link to={'/screen'} style={{ margin: 'auto 0' }}>
                                <CloseOutlined className='text-white' style={{ fontSize: '1.4em' }} />
                            </Link>
                        </Col>
                  </Row>
            </Layout>
        </React.Fragment>
    );
};

export default HeaderUI;