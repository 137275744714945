import { LoadingOutlined } from '@ant-design/icons';
import { DatePicker, Col, Row, Card, Table, Layout, Divider, Button, Form, Select, Upload, message, Space, Tree, Radio } from 'antd';
import { baseURL } from '../../../config';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import React, { useState, useEffect } from 'react';
import { getCustomers, getDataFilterUI } from '../../../api/ui/main';
import { exportKHSX } from '../../../api';
import dayjs from "dayjs";
import { getTreeSelect } from '../../../api/phase2/ui/main';
import CalculateTableHeight from '../../../components/calculateTableHeight';
import { getLotPlans } from '../../../api/phase2/ui/lot-plan';
import { manufatureQuicker } from '../../../routes/quickerCollection';

const { Sider } = Layout;
const { RangePicker } = DatePicker;

const KeHoachSanXuatTuDong = () => {
	const [listLines, setListLines] = useState([]);
	const [listNameProducts, setListNameProducts] = useState([]);
	const [listLoSX, setListLoSX] = useState([]);
	const [listCustomers, setListCustomers] = useState([]);
	const [totalPage, setTotalPage] = useState(1);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [params, setParams] = useState({ date: [dayjs(), dayjs()] });
	const [dateType, setDateType] = useState('date');
	const columns = [
		{ title: 'STT', dataIndex: 'stt', key: 'stt', render: (value, record, index) => ((page - 1) * pageSize) + index + 1, align: 'center', width: 50 },
		{ title: 'Lô sản xuất', dataIndex: 'lo_sx', align: 'center', width: 100 },
		{ title: 'Mã lot', dataIndex: 'lot_id', align: 'center', width: 120 },
		{ title: 'Công đoạn', dataIndex: 'line_name', align: 'center', width: 100, render: (_, record) => record?.line?.name },
		{ title: 'Số máy', dataIndex: 'machine_code', align: 'center', width: 100 },
		{ title: 'Mã sản phẩm', dataIndex: 'product_id', align: 'center', width: 110 },
		{ title: 'Tên sản phẩm', dataIndex: 'product_name', align: 'center', width: 180, render: (_, record) => record?.product?.name },
		{ title: 'Số lượng giao SX/ca', dataIndex: 'quantity', align: 'center', width: 180 },
		{ title: 'Thời gian bắt đầu', dataIndex: 'thoi_gian_bat_dau', align: 'center', width: 160 },
		{ title: 'Thời gian kết thúc', dataIndex: 'thoi_gian_ket_thuc', align: 'center', width: 160 },
		{ title: 'Ca sản xuất', dataIndex: 'ca_sx', align: 'center', width: 120 },
	];

	useEffect(() => {
		(async () => {
			const res1 = await getTreeSelect();
			setListLines(res1.data);
			const res5 = await getCustomers();
			setListCustomers(res5.data.map(e => {
				return { ...e, label: e.name, value: e.id }
			}));
		})()
	}, [])

	function btn_click(page = 1, pageSize = 25) {
		setPage(page);
		setPageSize(pageSize);
		loadListTable({...params, page, pageSize});
	}

	useEffect(() => {
		(async () => {
			var res = await getDataFilterUI({ khach_hang: params.khach_hang });
			if (res.success) {
				setListNameProducts(res.data.product.map(e => {
					return { ...e, label: e.name, value: e.id }
				}));
				setListLoSX(Object.values(res.data.lo_sx).map(e => {
					return { label: e, value: e }
				}));
			}
		})()
	}, [params.khach_hang])

	const [data, setData] = useState([]);
	const loadListTable = async (params) => {
		setLoading(true);
		const res = await getLotPlans(params);
		if (res.success) {
			setData(res.data.data || []);
			setTotalPage(res.data.total || 0);
		}
		setLoading(false)
	}
	useEffect(() => {
		btn_click();
	}, [])

	const [messageApi, contextHolder] = message.useMessage();

	const success = () => {
		messageApi.open({
			type: 'success',
			content: 'Upload file thành công',
		});
	};

	const error = () => {
		messageApi.open({
			type: 'error',
			content: 'Upload file lỗi',
		});
	};
	const [loadingUpload, setLoadingUpload] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingExport, setLoadingExport] = useState(false);
	const exportRecord = async () => {
		setLoadingExport(true);
		const res = await exportKHSX(params);
		if (res.success) {
			window.location.href = baseURL + res.data;
		}
		setLoadingExport(false);
	}
	const onCheck = (checkedKeys, info) => {
		const selectKeys = [];
		if (info.node.type === 'factory') {
			if (info.checked) {
				info.node.children.map(e => {
					selectKeys.push(e.key?.toString())
				})
			}
		} else {
			info.checkedNodes.map(e => {
				switch (e.type) {
					case 'line':
						selectKeys.push(e.key?.toString());
						break;
					case 'machine':
						selectKeys.push(e.line_id?.toString());
						break;
					default:
						break;
				}
			})
		}
		setParams({ ...params, line_id: [...new Set(selectKeys)] })
	};
	const customDateFormat = (value) => {
		switch (dateType) {
			case 'week':
				return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
				break;
			case 'month':
				return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
				break;
			case 'year':
				return (`Năm ${dayjs(value).format('YYYY')}`);
				break;
			default:
				return (dayjs(value).format('DD/MM/YYYY'));
				break;
		}
	}
	useEffect(() => {
		onChangeDate('start_date', params.date[0], dateType);
		onChangeDate('end_date', params.date[1], dateType);
	}, [dateType])
	const onChangeDate = (position, value, dateType) => {
		switch (dateType) {
			case 'week':
				if (position === 'start_date') {
					setParams({ ...params, date: [dayjs(value).startOf('isoWeek'), dayjs(params.date[1]).endOf('isoWeek')] });
				} else {
					setParams({ ...params, date: [dayjs(params.date[0]).startOf('isoWeek'), dayjs(value).endOf('isoWeek')] });
				}
				break;
			case 'month':
				if (position === 'start_date') {
					setParams({ ...params, date: [dayjs(value).startOf('month'), dayjs(params.date[1]).endOf('month')] })
				} else {
					setParams({ ...params, date: [dayjs(params.date[0]).startOf('month'), dayjs(value).endOf('month')] });
				}
				break;
			case 'year':
				if (position === 'start_date') {
					setParams({ ...params, date: [dayjs(value).startOf('year'), dayjs(params.date[1]).endOf('year')] })
				} else {
					setParams({ ...params, date: [dayjs(params.date[0]).startOf('year'), dayjs(value).endOf('year')] });
				}
				break;
			default:
				if (position === 'start_date') {
					setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] })
				} else {
					setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
				}
				break;
		}
	}
	const renderQuicker = () => {
		console.log(window.location.pathname);
		const className = (path) => {
			if (window.location.pathname.indexOf(path) > -1) {
				return 'active';
			} else {
				return 'inactive';
			}
		}
		return (
			<ul>
				{manufatureQuicker.map(e => {
					return (
						<li><Link to={e.path} className={'quicker ' + className(e.path)}>{e.name}</Link></li>
					)
				})}
			</ul>
		)
	}
	return <>
		{contextHolder}
		<Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
			<Col span={4} className='custom-col'>
				<Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
					<Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
				]}>
					<Form style={{ margin: '0 8px' }} layout="vertical">
						<Divider>Công đoạn</Divider>
						<Form.Item className='mb-3'>
							{
								listLines.length ?
									<Tree
										defaultExpandedKeys={[2]}
										style={{ maxHeight: 250, overflowY: 'auto' }}
										checkable
										selectable={false}
										onCheck={onCheck}
										treeData={listLines}
									/>
									:
									<LoadingOutlined />
							}
						</Form.Item>
						<Divider>Quicker</Divider>
						{renderQuicker()}
						<Divider>Thời gian truy vấn</Divider>
						<Radio.Group
							options={[
								{ value: 'date', label: 'Ngày', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
								{ value: 'week', label: 'Tuần', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
								{ value: 'month', label: 'Tháng', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
								{ value: 'year', label: 'Năm', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
							]}
							size='small'
							className='d-flex justify-content-center mb-2 w-100'
							onChange={(e) => setDateType(e.target.value)}
							value={dateType}
							optionType="button"
							buttonStyle="solid"
						/>
						<Space direction='vertical' style={{ width: '100%' }}>
							<DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.date[0]} format={customDateFormat} picker={dateType} />
							<DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.date[1]} format={customDateFormat} picker={dateType} />
						</Space>
						<Divider>Điều kiện truy vấn</Divider>
						<Form.Item label="Khách hàng" className='mb-3'>
							<Select
								allowClear
								showSearch
								placeholder="Chọn khách hàng"
								onChange={(value) => setParams({ ...params, khach_hang: value })}
								optionFilterProp="children"
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								popupMatchSelectWidth={listCustomers.length ? 400 : "100%"}
								options={listCustomers}
							/>
						</Form.Item>
						<Form.Item label="Tên sản phẩm" className='mb-3'>
							<Select
								allowClear
								showSearch
								onChange={(value) => {
									setParams({ ...params, ten_sp: value })
								}}
								placeholder="Nhập tên sản phẩm"
								optionFilterProp="children"
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								options={listNameProducts}
							/>
						</Form.Item>
					</Form>
				</Card>
			</Col>
			<Col span={20}>
				<Card style={{ height: '100%' }} title="Kế hoạch tự động" extra={
					<Space>
						<Upload
							showUploadList={false}
							name='files'
							action={baseURL + "/api/upload-ke-hoach-san-xuat"}
							headers={{
								authorization: 'authorization-text',
							}}
							onChange={(info) => {
								setLoadingUpload(true);
								if (info.file.status === 'error') {	
									setLoadingUpload(false);
									error()
								} else if (info.file.status === 'done') {
									if (info.file.response.success === true) {
										loadListTable(params);
										success();
										setLoadingUpload(false);
									} else {
										loadListTable(params);
										message.error(info.file.response.message);
										setLoadingUpload(false);
									}
								}
							}}
						>
							<Button style={{ marginLeft: '15px' }} type="primary" loading={loadingUpload}>
								Upload Excel
							</Button>
						</Upload>
						<Button type="primary" onClick={exportRecord} loading={loadingExport}>Export Excel</Button>
					</Space>
				}>
					<Table size='small' bordered
						locale={{ emptyText: '' }}
						className="table-tao-khsx"
						loading={loading}
						pagination={{
							current: page,
							pageSize: pageSize,
							size: "default",
							total: totalPage,
							onChange: (page, pageSize) => {
								btn_click(page, pageSize);
							}
						}}
						scroll={
							{
								x: true,
								y: CalculateTableHeight('table-tao-khsx')
							}
						}
						columns={columns}
						dataSource={data} />
				</Card>
			</Col>
		</Row>
	</>
}

export default KeHoachSanXuatTuDong;
