import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Table, Spin, FloatButton, Space, Modal, Form, InputNumber, message, Input } from 'antd';
import DataDetail2 from '../../../components/DataDetail2';
import '../style.scss';
import { useHistory, useParams, useQuery } from 'react-router-dom/cjs/react-router-dom.min';
import ScanButton from '../../../components/Button/ScanButton';
import SelectButton from '../../../components/Button/SelectButton';
import { getInfoPallet, getLine, getLineOverall, inTem, scanPallet } from '../../../api/oi/manufacture';
import { useReactToPrint } from 'react-to-print';
import Tem from '../../UI/Manufacture/Tem';
import { useRef } from 'react';
import Echo from 'laravel-echo';
import socketio from 'socket.io-client';
import { baseURL } from '../../../config';
import echo from '../../../helpers/echo';
import { getMachineOfLine } from '../../../api/ui/main';
import { MinusCircleOutlined, PlusOutlined, RollbackOutlined } from '@ant-design/icons';
import { endOfProduction, getLotProductionList, getMachineList, scanMaterial, getProductionOverall, scanManufacture, getLineList, updateLotErrorLog, findError, getLotErrorLogList, startMassProduction, updateOutputProduction } from '../../../api/phase2/oi/manufacture';
import { scanError } from '../../../api/oi/quality';
import ErrorsManagement from '../../../components/Popup/ErrorsManagement';
import PopupDauNoi from '../../../components/Popup/PopupDauNoi';

const defaultRow1 = [
    {
        title: 'SL KH ngày',
        key: 'tong_sl_trong_ngay_kh'
    },
    {
        title: 'SL T.Tế',
        key: 'tong_sl_thuc_te'
    },
    {
        title: 'SL Tem vàng',
        key: 'tong_sl_tem_vang',
        bg: '#f7ac27'
    },
    {
        title: 'SL NG',
        key: 'tong_sl_ng',
        bg: '#fb4b50'
    },
    {
        title: 'Tỷ lệ hoàn thành (%)',
        key: 'ty_le_hoan_thanh',
    },
]
const defaultRow2 = [
    {
        title: 'Mã Palet',
        key: 'lot_id'
    },
    {
        title: 'Tên sản phẩm',
        key: 'ten_sp'
    },
    {
        title: 'UPH (Ấn định)',
        key: 'uph_an_dinh'
    },
    {
        title: 'UPH (Thực tế)',
        key: 'uph_thuc_te'
    },
    {
        title: 'SL đầu ra (KH)',
        key: 'sl_dau_ra_kh'
    },
    {
        title: 'SL đầu ra (TT)',
        key: 'sl_dau_ra'
    },
    {
        title: 'SL đầu ra (TT OK)',
        key: 'sl_dau_ra_ok'
    },
    {
        title: 'Hao phí sản xuất',
        key: 'hao_phi'
    },
    {
        title: 'Định mức hao phí công đoạn',
        key: 'hao_phi_cong_doan'
    },
];
const columns = [
    {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        render: (value, record, index) => index + 1
    },
    {
        title: 'Lô sản xuất',
        dataIndex: 'lo_sx',
        key: 'lo_sx',
        align: 'center'
    },
    {
        title: 'Mã Pallet/cuộn',
        dataIndex: 'lot_id',
        key: 'lot_id',
        align: 'center'
    },
    {
        title: 'Tên sản phẩm',
        dataIndex: 'ten_sp',
        key: 'ten_sp',
        align: 'center'
    },
    {
        title: 'Mã hàng',
        dataIndex: 'ma_hang',
        key: 'ma_hang',
        align: 'center'
    },
    {
        title: 'Kế hoạch',
        key: 'ke_hoach',
        children: [
            {
                title: 'TG bắt đầu',
                dataIndex: 'thoi_gian_bat_dau_kh',
                key: 'thoi_gian_bat_dau_kh',
                align: 'center',
            },
            {
                title: 'Số lượng đầu ra (pcs)',
                dataIndex: 'sl_dau_ra_kh',
                key: 'sl_dau_ra_kh',
                align: 'center'
            },
        ]
    },
    {
        title: 'Thực tế',
        key: 'thuc_te',
        children: [
            {
                title: 'TG bắt đầu',
                dataIndex: 'thoi_gian_bat_dau',
                key: 'thoi_gian_bat_dau',
                align: 'center',
            },
            {
                title: 'Số lượng đầu vào (m)',
                dataIndex: 'sl_dau_vao_hang_loat',
                key: 'sl_dau_vao',
                align: 'center',
            },
            {
                title: 'Số lượng đầu ra (pcs)',
                dataIndex: 'sl_dau_ra_hang_loat',
                key: 'sl_dau_ra',
                align: 'center'
            },
            {
                title: 'Số lượng đầu ra OK (pcs)',
                dataIndex: 'sl_dau_ra_ok',
                key: 'sl_dau_ra_kh',
                align: 'center'
            },
            {
                title: 'Số lượng tem vàng (pcs)',
                dataIndex: 'sl_tem_vang',
                key: 'sl_tem_vang',
                align: 'center',
                className: 'yellow',
            },
            {
                title: 'Số lượng NG (pcs)',
                dataIndex: 'sl_ng',
                key: 'sl_ng',
                align: 'center',
                className: 'red',
            },
        ]
    },
    {
        title: 'Tỉ lệ hoàn thành (%)',
        dataIndex: 'ti_le_ht',
        key: 'ti_le_ht',
        align: 'center',
    },
];
const P2ManualManufacture = (props) => {
    document.title = "Sản xuất";
    const { line } = useParams();
    const history = useHistory();
    const [options, setOption] = useState([])
    const [machineOptions, setMachineOption] = useState([])
    const [loading, setLoading] = useState(false);
    const [selectedLot, setSelectedLot] = useState();
    const [listCheck, setListCheck] = useState([]);
    const machine = (new URLSearchParams(window.location.search).get('machine') || '');
    const [form] = Form.useForm();
    const [errorList, setErrorList] = useState([]);

    const [dataOverall, setDataOverall] = useState();

    const fetchLine = async () => {
        const lineList = await getLineList({ type: 'sx' });
        setOption(lineList.data);
        // setMachineOption([]);
    }
    const fetchOverall = async () => {
        const lineOverall = await getProductionOverall({ line_id: line, machine_code: machine });
        setDataOverall(lineOverall.data);
    }
    const fetchLotDataTable = async () => {
        setLoading(true);
        const infoPallet = await getLotProductionList({ line_id: line, machine_code: machine });
        if (infoPallet.success) {
            setData((infoPallet.data ?? []).map((e, i) => {
                if (e.status == 1) {
                    setSpecifiedRowKey(i);
                }
                return { ...e, key: i };
            }));
            setSelectedLot(infoPallet.data.find(e => e.status === 1));
        } else {
            setSelectedLot();
        }
        setErrorList([]);
        setListCheck([]);
        setLoading(false);
    }
    const fetchMachine = async () => {
        const machineList = await getMachineList({ line });
        setMachineOption(machineList.data.map(e => ({ ...e, value: e.code, label: e.code })));
    }

    const redirectBetweenView = (machineOptions, machine) => {
        if (machineOptions.length) {
            if (!machine) {
                history.push({ search: '?machine=' + machineOptions[0]?.value });
            } else {
                const target = machineOptions.find(e => e.value === machine);
                if (!target?.is_iot) {
                    history.push({ pathname: '/manual-manufacture/' + line, search: '?machine=' + target?.value });
                } else {
                    history.push({ pathname: '/manufacture/' + line, search: '?machine=' + target?.value });
                }
            }
        }
    }
    useEffect(() => {
        (async () => {
            await fetchLine();
        })()
    }, [])
    useEffect(() => {
        fetchMachine();
    }, [line])
    useEffect(() => {
        redirectBetweenView(machineOptions, machine);
    }, [machineOptions]);
    useEffect(() => {
        fetchOverall();
        setSpecifiedRowKey(null);
        if (line && machine) {
            fetchLotDataTable();
        }
    }, [line, machine])
    const [data, setData] = useState([]);
    const onChangeLine = (value) => {
        history.push('/manufacture/' + value)
    }
    const onChangeMachine = (value) => {
        // if (value) {
        //     history.push({ search: '?machine=' + value });
        // } else {
        //     history.push({ search: '' });
        // }
        redirectBetweenView(machineOptions, value);
    }
    const onScan = async (result) => {
        if (line === '24' || line === '25') {
            const selectedInfo = data.find(e => e.status === 0);
            if (!selectedInfo || data.length === 0) {
                message.error('Không tìm thấy lô sản xuất nào cần chạy');
                return;
            }
            const params = { material_id: result, line_id: line, machine_code: machine, lot_id: selectedInfo.lot_id };
            var res = await scanMaterial(params);
            if (res.success) {
                fetchLotDataTable();
            }
        } else {
            const params = { lot_id: result, machine_code: machine, line_id: line };
            var res = await scanManufacture(params);
            if (res.success) {
                fetchLotDataTable();
            }
        }
    }

    const rowClassName = (record, index) => {
        switch (record.status) {
            case 1:
                return 'table-row-green';
                break;
            case 2:
                return 'table-row-grey';
                break;
            default:
                return ""
                break;
        }
    }

    useEffect(() => {
        if (!selectedLot) {
            setListCheck([])
        }
    }, [selectedLot])

    const componentRef1 = useRef();
    const handlePrint = async () => {
        if (selectedLot) {
            const res = await endOfProduction({ lot_id: selectedLot.lot_id, machine_code: machine, line_id: line });
            if (res.success) {
                setListCheck([res.data]);
                fetchLotDataTable();
            }
        }
    }

    const print = useReactToPrint({
        content: () => componentRef1.current
    });
    useEffect(() => {
        if (listCheck.length > 0) {
            print();
        }
    }, [listCheck])

    const tableRef = useRef();
    const [specifiedRowKey, setSpecifiedRowKey] = useState(null);
    const handleScrollToRow = () => {
        if (specifiedRowKey !== null && tableRef.current) {
            tableRef.current?.scrollTo({ key: specifiedRowKey, behavior: 'smooth' });
        }
    };
    useEffect(() => {
        if (data.length > 0) {
            handleScrollToRow();
        }
    }, [specifiedRowKey]);

    const openErrorListModal = async () => {
        if (selectedLot) {
            var res = await getLotErrorLogList({ lot_id: selectedLot.lot_id });
            if (res.success) {
                setErrorList(res.data.errorList ?? []);
                form.setFieldsValue(res.data.log ?? {});
                // setOpenModalErrorList(true);
            }
        }
    }
    useEffect(() => {
        form.setFieldsValue({
            errorList: errorList.map(item => ({ title: item.id, value: item.value }))
        });
    }, [form, errorList]);

    const [openManualInputModal, setOpenManualInputModal] = useState(false);
    const onFinish = async (values) => {
        console.log(values);

        var res = await updateOutputProduction({ line_id: line, machine_code: machine, lot_id: selectedLot?.lot_id, output: values.output });
        if (res.success) {
            form.resetFields();
            setOpenManualInputModal(false);
        }
        fetchLotDataTable();
    }

    const onOpenManualInput = () => {
        setOpenManualInputModal(true);
        form.resetFields();
    }
    return (
        <React.Fragment>
            <Spin spinning={loading}>
                <Row className='mt-3' gutter={[12, 12]}>
                    <Col span={4}>
                        <SelectButton value={options.length > 0 && parseInt(line)} options={options} label="Chọn công đoạn" onChange={onChangeLine} />
                    </Col>
                    <Col span={4}>
                        <SelectButton value={machineOptions.length > 0 && machineOptions.some(e => e.value == machine) ? machine : null} options={machineOptions} label="Chọn thiết bị" onChange={onChangeMachine} allowClear={true} />
                    </Col>
                    <Col span={16}>
                        <DataDetail2 data={defaultRow1} value={dataOverall} />
                    </Col>
                    <Col span={24}>
                        <ScanButton onScan={onScan} />
                    </Col>
                    <Col span={24}>
                        <DataDetail2 data={defaultRow2} value={selectedLot} />
                    </Col>
                    <Col span={8}>
                        <Button size='large' disabled={!selectedLot} type={!selectedLot?.thoi_gian_bam_may ? 'default' : 'primary'} style={{ height: '100%', width: '100%' }} onClick={() => onOpenManualInput()}>{"Nhập sản lượng"}</Button>
                    </Col>
                    <Col span={8}>
                        {/* <div className='d-flex flex-column gap-2 w-100 justify-content-around h-100'> */}
                            <PopupDauNoi lotId={selectedLot?.lot_id} onSubmit={null} data={errorList} onClick={openErrorListModal} />
                            {/* <Button block size='large' type='primary' style={{ height: '100%', width: '100%' }} onClick={handlePrint}>In tem</Button>
                        </div> */}
                    </Col>
                    <Col span={8}>
                        <Button block size='large' type='primary' style={{ height: '100%', width: '100%' }} onClick={handlePrint}>In tem</Button>
                        <div className="report-history-invoice">
                            <Tem listCheck={listCheck} ref={componentRef1} />
                        </div>
                    </Col>
                    <Col span={24}>
                        <Table
                            ref={tableRef}
                            scroll={{
                                x: 200,
                                y: 350,
                            }}
                            rowHoverable={false}
                            size='small'
                            rowClassName={rowClassName}
                            pagination={false}
                            bordered
                            columns={columns}
                            dataSource={data}
                            footer={() => specifiedRowKey !== null ? (
                                <FloatButton
                                    type="primary"
                                    icon={<RollbackOutlined />}
                                    tooltip={<div>Quay lại lot đang chạy</div>}
                                    style={{ position: 'absolute', bottom: 40, right: 30 }}
                                    onClick={handleScrollToRow}
                                />
                            ) : null}
                        />
                    </Col>
                </Row>
            </Spin>
            <Modal title={"Nhập sản lượng " + (!selectedLot?.thoi_gian_bam_may ? 'vào hàng' : 'sản xuất')} open={openManualInputModal} onCancel={() => setOpenManualInputModal(false)} onOk={() => form.submit()}>
                <Form form={form} onFinish={onFinish} initialValues={{ output: 0 }}>
                    <Form.Item name='output' label="Sản lượng"><InputNumber inputMode='numeric' style={{ width: '100%' }} /></Form.Item>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default P2ManualManufacture;