import {
  DatePicker,
  Col,
  Row,
  Card,
  Table,
  Divider,
  Button,
  Form,
  Select,
  Upload,
  Space,
  Radio,
  message,
} from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import React, { useState, useEffect } from "react";
import { getCustomers, getDataFilterUI } from "../../../api/ui/main";
import dayjs from "dayjs";
import {
  exportProductOrders,
  getProductOrders,
  importProductOrders,
} from "../../../api/phase2/master_data/product-order";
import CalculateTableHeight from "../../../components/calculateTableHeight";
import { getCustomer } from "../../../api/ui/customer";
import { getProduct } from "../../../api/ui/product";
import { manufatureQuicker } from "../../../routes/quickerCollection";
import { downloadExcel } from "../../../helpers/data_blob";

const Orders = () => {
  const [listNameProducts, setListNameProducts] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [listCustomers, setListCustomers] = useState([]);
  const [params, setParams] = useState({
    start_date: dayjs(),
    end_date: dayjs(),
  });
  const [dateType, setDateType] = useState("date");
  const [customerOptions, setCustomerOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      align: "center",
      width: "40px",
      render: (value, record, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Số đơn hàng",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "100px",
    },
    {
      title: "Ngày đặt hàng",
      dataIndex: "order_date",
      align: "center",
      width: "100px",
    },
    {
      title: "Mã khách hàng",
      dataIndex: "customer_id",
      key: "customer_id",
      align: "center",
      width: "100px",
    },
    {
      title: "Mã hàng hoá",
      dataIndex: "product_id",
      key: "product_id",
      align: "center",
      width: "90px",
    },
    {
      title: "Tên hàng hoá",
      dataIndex: "product_name",
      key: "product_name",
      align: "center",
      width: "150px",
      render: (value, record, index) => record?.product?.name ?? "",
    },
    {
      title: "Số lượng",
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
      width: "100px",
    },
    {
      title: "Ngày giao hàng",
      dataIndex: "delivery_date",
      align: "center",
      width: "100px",
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
      align: "center",
      width: "330px",
      ellipsis: true,
    },
  ];

  useEffect(() => {
    (async () => {
      const res5 = await getCustomers();
      setListCustomers(
        res5.data.map((e) => {
          return { ...e, label: e.name, value: e.id };
        })
      );
    })();
  }, []);

  function btn_click(page = 1, pageSize = 20) {
    setPage(page);
    setPageSize(pageSize);
    loadListTable({ ...params, page, pageSize });
  }

  useEffect(() => {
    (async () => {
      var res = await getDataFilterUI({ khach_hang: params.khach_hang });
      if (res.success) {
        setListNameProducts(
          res.data.product.map((e) => {
            return { ...e, label: e.name, value: e.id };
          })
        );
      }
    })();
  }, [params.khach_hang]);

  const [data, setData] = useState([]);
  const loadListTable = async (params) => {
    setLoading(true);
    params.withs = "product.unit";
    const res = await getProductOrders(params);
    setData(
      (res.data?.data ?? []).map((e) => {
        return { ...e, key: e.id };
      })
    );
    setTotalPage(res.data.total);
    setLoading(false);
  };
  useEffect(() => {
    (async () => {
      loadListTable(params);
    })();
  }, []);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const customDateFormat = (value) => {
    switch (dateType) {
      case "week":
        return `Tuần ${dayjs(value).format("WW - GGGG")}`;
        break;
      case "month":
        return `Tháng ${dayjs(value).format("MM")} - ${dayjs(value).format(
          "YYYY"
        )}`;
        break;
      case "year":
        return `Năm ${dayjs(value).format("YYYY")}`;
        break;
      default:
        return dayjs(value).format("DD/MM/YYYY");
        break;
    }
  };
  useEffect(() => {
    onChangeDate("start_date", params.start_date, dateType);
    onChangeDate("end_date", params.end_date, dateType);
  }, [dateType]);
  const onChangeDate = (position, value, dateType) => {
    switch (dateType) {
      case "week":
        if (position === "start_date") {
          setParams({
            ...params,
            start_date: dayjs(value).startOf("isoWeek"),
            end_date: dayjs(params.end_date).endOf("isoWeek"),
          });
        } else {
          setParams({
            ...params,
            start_date: dayjs(params.start_date).startOf("isoWeek"),
            end_date: dayjs(value).endOf("isoWeek"),
          });
        }
        break;
      case "month":
        if (position === "start_date") {
          setParams({
            ...params,
            start_date: dayjs(value).startOf("month"),
            end_date: dayjs(params.end_date).endOf("month"),
          });
        } else {
          setParams({
            ...params,
            start_date: dayjs(params.start_date).startOf("month"),
            end_date: dayjs(value).endOf("month"),
          });
        }
        break;
      case "year":
        if (position === "start_date") {
          setParams({
            ...params,
            start_date: dayjs(value).startOf("year"),
            end_date: dayjs(params.end_date).endOf("year"),
          });
        } else {
          setParams({
            ...params,
            start_date: dayjs(params.start_date).startOf("year"),
            end_date: dayjs(value).endOf("year"),
          });
        }
        break;
      default:
        if (position === "start_date") {
          setParams({
            ...params,
            start_date: dayjs(value),
            end_date: dayjs(params.end_date),
          });
        } else {
          setParams({
            ...params,
            start_date: dayjs(params.end_date),
            end_date: dayjs(value),
          });
        }
        break;
    }
  };

  const fetchCustomer = async () => {
    setLoading(true);
    const response = await getCustomer();
    if (response?.success) {
      setCustomerOptions(
        (response.data?.data ?? []).map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        })
      );
    }
    setLoading(false);
  };

  const fetchProduct = async () => {
    setLoading(true);
    const response = await getProduct();
    if (response?.success) {
      setProductOptions(
        (response.data?.data ?? []).map((item) => {
          return {
            label: (
              <>
                <span style={{ fontWeight: 600 }}>{item?.id}</span>
                <small style={{ color: "gray" }}>({item?.name})</small>
              </>
            ),
            value: item?.id,
          };
        })
      );
    }
    setLoading(false);
  };

  const renderQuicker = () => {
    console.log(window.location.pathname);
    const className = (path) => {
      if (window.location.pathname.indexOf(path) > -1) {
        return "active";
      } else {
        return "inactive";
      }
    };
    return (
      <ul>
        {manufatureQuicker.map((e) => {
          return (
            <li>
              <Link to={e.path} className={"quicker " + className(e.path)}>
                {e.name}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  };
  useEffect(() => {
    fetchProduct();
    fetchCustomer();
  }, []);

  const handleExportData = async () => {
    setLoading(true);
    const response = await exportProductOrders();
    if (response.success) {
      const { data, type, file } = response.data;
      // Download
      if (data && type && file) {
        downloadExcel(data, type, file);
      } else message.error('Data invalid!');
    }
    setLoading(false);
  }

  return (
    <>
      <Row style={{ padding: "8px" }} className="custom-row" gutter={[8, 8]}>
        <Col span={4} className="custom-col">
          <Card
            className="custom-ant-card"
            style={{ height: "100%" }}
            bodyStyle={{ padding: 0 }}
            actions={[
              <Button
                type="primary"
                style={{ width: "90%" }}
                onClick={() => btn_click()}
              >
                Truy vấn
              </Button>,
            ]}
          >
            <div className="mb-3">
              <Form style={{ margin: "0 15px" }} layout="vertical">
                <Divider>Quicker</Divider>
                {renderQuicker()}
                <Divider>Thời gian truy vấn</Divider>
                <Radio.Group
                  size="small"
                  options={[
                    {
                      value: "date",
                      label: "Ngày",
                      style: {
                        width: "25%",
                        justifyContent: "center",
                        display: "flex",
                      },
                    },
                    {
                      value: "week",
                      label: "Tuần",
                      style: {
                        width: "25%",
                        justifyContent: "center",
                        display: "flex",
                      },
                    },
                    {
                      value: "month",
                      label: "Tháng",
                      style: {
                        width: "25%",
                        justifyContent: "center",
                        display: "flex",
                      },
                    },
                    {
                      value: "year",
                      label: "Năm",
                      style: {
                        width: "25%",
                        justifyContent: "center",
                        display: "flex",
                      },
                    },
                  ]}
                  className="d-flex justify-content-center mb-2 w-100"
                  onChange={(e) => setDateType(e.target.value)}
                  value={dateType}
                  optionType="button"
                  buttonStyle="solid"
                />
                <Space direction="vertical" style={{ width: "100%" }}>
                  <DatePicker
                    allowClear={false}
                    placeholder="Bắt đầu"
                    style={{ width: "100%" }}
                    onChange={(value) =>
                      onChangeDate("start_date", value, dateType)
                    }
                    value={params.start_date}
                    format={customDateFormat}
                    picker={dateType}
                  />
                  <DatePicker
                    allowClear={false}
                    placeholder="Kết thúc"
                    style={{ width: "100%" }}
                    onChange={(value) =>
                      onChangeDate("end_date", value, dateType)
                    }
                    value={params.end_date}
                    format={customDateFormat}
                    picker={dateType}
                  />
                </Space>
                <Divider>Điều kiện truy vấn</Divider>
                <Form.Item label="Khách hàng" className="mb-3">
                  <Select
                    allowClear
                    showSearch
                    placeholder="Chọn khách hàng"
                    onChange={(value) =>
                      setParams({ ...params, khach_hang: value })
                    }
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={listCustomers}
                  />
                </Form.Item>
                <Form.Item label="Tên sản phẩm" className="mb-3">
                  <Select
                    allowClear
                    showSearch
                    onChange={(value) => {
                      setParams({ ...params, product_id: value });
                    }}
                    placeholder="Nhập tên sản phẩm"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={listNameProducts}
                  />
                </Form.Item>
              </Form>
            </div>
          </Card>
        </Col>
        <Col className="custom-col-table" span={20}>
          <Card
            style={{ height: "100%" }}
            title="Đơn hàng"
            extra={
              <Space>
                <Upload
                  showUploadList={false}
                  customRequest={async ({ file, onSuccess, onError }) => {
                    const formData = new FormData();
                    formData.append("file", file);
                    const res = await importProductOrders(formData);
                    if (res.success) {
                      loadListTable(params);
                      setLoadingUpload(false);
                    } else {
                      loadListTable(params);
                      setLoadingUpload(false);
                    }
                  }}
                >
                  <Button
                    style={{ marginLeft: "15px" }}
                    type="primary"
                    loading={loadingUpload}
                  >
                    Upload
                  </Button>
                </Upload>
                <Button type="primary" onClick={handleExportData}>Xuất dữ liệu</Button>
              </Space>
            }
          >
            <Table
              size="small"
              bordered
              loading={loading}
              locale={{ emptyText: "" }}
              className="table-order"
              pagination={{
                current: page,
                pageSize: 20,
                size: "small",
                total: totalPage,
                onChange: (page, pageSize) => {
                  btn_click(page, pageSize);
                },
              }}
              scroll={{
                // x: '2040px',
                y: CalculateTableHeight("table-order"),
              }}
              columns={columns}
              dataSource={data}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Orders;
